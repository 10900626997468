.pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin: 5px;
    cursor: pointer;
}

ul.pagination li a {
    text-decoration: none;
    color: black;
    font-size: 1rem;
}

ul.pagination li.active a {
    color: aliceblue;
}

ul.pagination li.active {
    background-color: #4671E9;
    border-radius: 13px;
}

ul.pagination li:hover {
    background-color: #90a9f1;
    border-radius: 13px;
    a{
        color: aliceblue;
    }
}

ul.pagination li:first-child{
    a{
        color: #666;
        font-weight: bold;
    }
    &:hover{
        background-color: white;
        a{
            color: black;
        }
    }
}

ul.pagination li:nth-child(8){
    a{
        color: #666;
        font-weight: bold;
    }
    &:hover{
        background-color: white;
        a{
            color: black;
        }
    }
}

ul.pagination li:nth-child(2){
    a{
        color: #666;
        font-weight: bold;
    }
    &:hover{
        background-color: white;
        a{
            color: black;
        }
    }
}

ul.pagination li:last-child{
    a{
        color: #666;
        font-weight: bold;
    }
    &:hover{
        background-color: white;
        a{
            color: black;
        }
    }
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}